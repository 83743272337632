import './App.css';
import { useEffect } from 'react';
import axios from 'axios';

function App() {
  useEffect(() => {
    const key = new URLSearchParams(window.location.search).get(
      "partnership_id"
    );
    var authData = {
      "licence": "ASS#SSOnE)y0ùrOmit6cYdypà4+m",
      "code_courtier": "813260_",
      "code_utilisateur": "",
      "nom_utilisateur": "",
      "prenom_utilisateur": "",
      "raison_sociale": "",
    }
    const apiKey = key ? key : process.env.REACT_APP_API_KEY;
    localStorage.setItem("api_key", apiKey);
    let config = {
      headers: {
        Authorization: `Bearer ${apiKey}`,
      },
    };
    axios
      .post(`${process.env.REACT_APP_GEOPROD_APIURL}login`, {}, config)
      .then((res) => {
        localStorage.setItem("token", res.data.Acces_Token);
        authData.code_courtier = authData.code_courtier + res?.data?.id_externe;
        authData.nom_utilisateur = res?.data?.nom;
        authData.prenom_utilisateur = res?.data?.prenom;
        authData.code_utilisateur = res?.data?.email;
        axios
          .get(
            `${process.env.REACT_APP_GEOPROD_APIURL}organismes/get_by_id/${res.data.user.entreprise_id}`,
            {
              headers: {
                idSession: localStorage.getItem("token"),
              },
            }
          )
          .then((res) => {
            authData.raison_sociale = res?.data?.nom
            authData.email_courtier = res?.data?.mail
            authData.telephone_courtier = res?.data?.tel

            axios.post(`${process.env.REACT_APP_BASE_URL}/auth`, authData).then((res) => {
                window.location.replace(process.env.REACT_APP_EXTERNAL_URL + res.data)
            }).catch((err) => console.log(err))
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => {
        console.log(err);
      });

  }, [])
  return (
    <div class="ring">Loading
  <span></span>
</div>
  );
}

export default App;
